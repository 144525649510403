<template>
  <div class="auth-wrapper">
    <div class="col-md-4 mx-auto form-holder">
      <div class="my-5 d-flex align-items-center justify-content-center">
        <img
          :src="
            AppTheme === 'dark_theme'
              ? require('@/assets/img/brand/ana_white.png')
              : require('@/assets/img/brand/ana.png')
          "
          class="logo"
          alt=""
        />
      </div>
      <h3 class="text-center ana_h3 ana_text-grey">
        Please proceed to reset your password
      </h3>
      <form @submit.prevent="resetPassword" class="app-form">
        <div class="mt-5">
          <div class="form-group col-lg-12">
            <label for="password" class="ana-label">Password</label>
            <div class="input-group mb-3 ana-input__group">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"
                  ><i class="ti-lock"></i
                ></span>
              </div>
              <input
                :type="type"
                name="password"
                class="form-control ana-input"
                v-model.trim="$v.password.$model"
                placeholder="min. 6 characters"
                required
              />
            </div>
            <InlineAlert
              alertMessage="Password should be at least 6 characters"
              alertType="error"
              v-if="password && $v.password.$invalid"
            />
          </div>
          <div class="form-group col-lg-12">
            <label for="password" class="ana-label">Confirm Password</label>
            <div class="input-group mb-3 ana-input__group">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2"
                  ><i class="ti-lock"></i
                ></span>
              </div>
              <input
                :type="type"
                name="password"
                class="form-control ana-input"
                v-model.trim="$v.confirm_password.$model"
                placeholder="*******"
                required
              />
            </div>
            <InlineAlert
              alertMessage="Password should be at least 6 characters"
              alertType="error"
              v-if="confirm_password && $v.confirm_password.$invalid"
            />
            <InlineAlert
              alertMessage="Passwords do not match"
              alertType="error"
              v-if="confirm_password && password !== confirm_password"
            />
          </div>
          <div class="form-group col-lg-12">
            <div class="d-flex align-items-center">
              <small class="mr-2 mb-2"> Show passwords</small>
              <label class="switch"
                ><input
                  type="checkbox"
                  @change="showPassword"
                  :checked="type == 'text'"
                />
                <div></div>
              </label>
            </div>
          </div>
          <div class="mt-3 mb-3 d-flex justify-content-center" v-if="isLoading">
            <div class="linear-loader"></div>
          </div>
          <div class="form-group col-lg-12">
            <button
              type="submit"
              class="ana-btn ana-btn__fill w-100"
              :disabled="
                isLoading || $v.$invalid || password !== confirm_password
              "
            >
              Reset Password
            </button>
          </div>
        </div>
        <div class="mt-5 text-center" v-if="newLink">
          <p class="ana-text--medium ana_text-grey">
            Please proceed to
            <router-link to="/ForgotPassword/" class="ana_text-secondary">
              submit</router-link
            >
            a new password reset request if issue persists.
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  name: "PasswordReset",
  data() {
    return {
      password: "",
      confirm_password: "",
      type: "password",
      newLink: false,
      isLoading: false,
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    confirm_password: {
      required,
      minLength: minLength(6),
    },
  },
  methods: {
    resetPassword() {
      this.newLink = false;
      if (this.password !== this.confirm_password) {
        this.$toast(`Passwords do not match`, {
          timeout: 2000,
          type: "error",
        });
      } else {
        this.isLoading = true;
        const user = {
          password: this.password,
          token: this.$route.params.token,
        };
        axios
          .post("user/resetPassword", user)
          .then((res) => {
            this.isLoading = false;
            this.$toast(`Password updated successfully. Proceed to login`, {
              timeout: 6000,
              type: "success",
            });
            if (res.data.success) {
              this.$router.push("/login");
            }
          })
          .catch((err) => {
            this.isLoading = false;
            console.log(err);
            this.$toast(`${err.response.data.message}`, {
              timeout: 8000,
              type: "error",
            });
            this.newLink = true;
          });
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
  },
};
</script>
